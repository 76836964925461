import React from 'react'
import DefaultEllipse from '../images/ellipse.svg'
import * as styles from '@css/ellipse.module.css'
import classNames from 'classnames'

export const Ellipse = ({ left, top, width, height, ellipse, className }) => {
  const ellipseStyle = {
    backgroundImage: `url(${ellipse ? ellipse : DefaultEllipse})`,
    filter: !ellipse ? 'blur(20px)' : 'none',
  }

  return (
    <div
      className={classNames(styles.ellipse, className)}
      style={ellipseStyle}
    ></div>
  )
}
