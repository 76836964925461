import { graphql, useStaticQuery } from 'gatsby'
import React, { createContext, useContext, useEffect, useState } from 'react'

const SharedImagesContext = createContext()

const SharedImagesProvider = ({ children }) => {
  const [images, setImages] = useState([])
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { in: ["png", "jpg", "gif"] } }) {
        totalCount
        nodes {
          name
          publicURL
        }
      }
    }
  `)
  useEffect(() => {
    setImages(allFile.nodes)
  }, [allFile])

  return (
    <SharedImagesContext.Provider value={images}>
      {children}
    </SharedImagesContext.Provider>
  )
}

const useGetImage = imageName => {
  const images = useContext(SharedImagesContext)
  const image = images.find(image => image.name === imageName)
  if (images.length > 0) {
    if (!image) throw new Error(`Image ${imageName} not found`)
    return image.publicURL
  }
}

export { SharedImagesProvider, useGetImage }
