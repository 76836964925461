import React from 'react'
import * as styles from '@css/solution.module.css'
import { GifEmoji } from '@components'

export const Solution = ({ children, problem, emoji }) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.problem}>
        {emoji && <GifEmoji emoji={emoji} className={styles.problemIcon} />}
        {problem}
      </h2>
      <h3 className={styles.solutionText}>
        <span>&gt;</span>
        {children}
      </h3>
    </div>
  )
}
