import React from 'react'
export const GradientPolygon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#23f6ef" />
        <stop offset="0.548" stop-color="#6033f8" />
        <stop offset="1" stop-color="#ff00d5" />
      </linearGradient>
    </defs>
    <path
      id="Polygon_3"
      data-name="Polygon 3"
      d="M9.672,2.828a4,4,0,0,1,5.657,0l6.843,6.843a4,4,0,0,1,0,5.657l-6.843,6.843a4,4,0,0,1-5.657,0L2.828,15.328a4,4,0,0,1,0-5.657Z"
      fill="url(#linear-gradient)"
    />
  </svg>
)
