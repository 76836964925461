import React, { createContext, useContext } from 'react'

const ContentContext = createContext()

export const ContentProvider = ({ children, content }) => (
  <ContentContext.Provider value={content}>{children}</ContentContext.Provider>
)

export const useContent = () => {
  const content = useContext(ContentContext)
  return content
}
